<template>
    <div class="page-wrapper">
      <Banner1 />
      <Marcas />
      <Banner2 />
      <BuscarPneus />
      <BannerDuplo />
      <Destaques />
      <PerguntasFrequentes />
      
    </div>
  </template>
  
  <script>
  import Banner1 from '@/components/home/Banner1.vue';
  import Marcas from '@/components/home/Marcas.vue';
  import Banner2 from '@/components/home/Banner2.vue';
  import Destaques from '@/components/home/Destaques.vue';
  import BannerDuplo from '@/components/home/BannerDuplo.vue';
  import PerguntasFrequentes from '@/components/home/PerguntasFrequentes.vue';
  import BuscarPneus from '@/components/home/BuscarPneus.vue';
 
  
  export default {
    components: {
      Banner1,
      Marcas,
      Banner2,
      Destaques,
      BannerDuplo,
      PerguntasFrequentes,
      BuscarPneus,
     
    },
    name: 'Home',
  }
  </script>
  
  <style scoped>
  .page-wrapper {
  }
  </style>
  