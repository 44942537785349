<template>
  <section class="topbar-four">
    <div class="container">
      <div class="topbar-four__inner">
        <div class="topbar-four__logo">
          <a href="/">
            <img src="@/assets/images/logo.png" alt="Karoons HTML" width="350" />
          </a>
        </div>
        <!-- /.main-header__logo -->
        <div class="topbar-four__search">
          <form @submit.prevent="searchProducts" class="d-flex">
            <input v-model="searchText" @input="debouncedHandleInput" @focus="showSuggestions = true" @blur="handleBlur"
              type="search" class="search-input"
              placeholder="DIGITE O QUE ESTÁ BUSCANDO: MARCA; MODELO DO VEÍCULO; MEDIDAS..." autocomplete="off" />
            <button type="submit">
              <i class="flaticon-search"></i>
            </button>
          </form>
        </div>
        <ul class="list-unstyled topbar-four__info">
          <li>
            <div class="topbar-four__info__language">
              <a href="#"><i class="flaticon-language"></i>Idioma</a>
              <div class="topbar-four__info__language__dropdown">
                <a href="#">Português</a>
                <a href="#">Español</a>
              </div>
            </div>
          </li>
          <li>
            <div class="topbar-four__info__currency" @mouseenter="showDropdown" @mouseleave="hideDropdown">
              <a href="#"><i class="flaticon-dollar"></i>Cotação</a>
              <transition name="dropdown-fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
                <div class="topbar-four__info__currency__dropdown" v-show="isDropdownVisible"
                  @mouseenter="keepDropdownVisible" @mouseleave="hideDropdown">
                  <template v-if="currencies.length">
                    <div v-for="currency in currencies" :key="currency.target_currency.id" class="currency-item">
                      <img v-if="currency.target_currency.symbol === 'R$'" src="@/assets/images/bandeira-brasil.webp"
                        alt="Brasil" class="flag-icon" />
                      <img v-if="currency.target_currency.symbol === 'G$'" src="@/assets/images/bandeira-paraguai.webp"
                        alt="Paraguai" class="flag-icon" />
                      {{ currency.target_currency.symbol }} {{ formatTax(currency.tax,
                        currency.target_currency.decimals) }}
                    </div>
                  </template>
                  <template v-else>
                    <span>Carregando...</span>
                  </template>
                </div>
              </transition>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';

const searchText = ref('');
const showSuggestions = ref(false);
const isDropdownVisible = ref(false);
const currencies = ref([]);
const router = useRouter();
const route = useRoute();

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const searchProducts = async () => {
  if (searchText.value.trim()) {
    router.push({
      name: 'produtos',
      query: {
        text: searchText.value
      }
    });
  }
};

const formatTax = (tax, decimals) => {
  return parseFloat(tax).toFixed(decimals).replace('.', ',');
};

const showDropdown = () => {
  isDropdownVisible.value = true;
};

const hideDropdown = () => {
  isDropdownVisible.value = false;
};

const keepDropdownVisible = () => {
  isDropdownVisible.value = true;
};

watch(
  () => route.query.text,
  (newText, oldText) => {
    if (newText !== oldText) {
      searchText.value = newText || '';
    }
  }
);

onMounted(async () => {
  try {
    const response = await axios.get('https://api-imperiopneus.agcodecraft.com/api/public/exchange-rates');
    currencies.value = response.data;
  } catch (error) {
    console.error('Erro ao carregar cotações:', error);
  }
});

</script>


<style>
.search-input::placeholder {
  font-size: 16px;
  color: #888787;
}

.currency-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
  gap: 8px;
  font-size: 13px;
}

.flag-icon {
  width: 18px;
  height: auto;
}

.currency-tax {
  font-weight: bold;
  margin-left: 0;
}

.topbar-four__info__language {
  visibility: hidden;
}

.topbar-four__info__currency__dropdown {
  border: 1px solid red;
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: -15px;
  padding-top: -20px;
  z-index: 1000;
}

.topbar-four__info__currency {
  position: relative;
}

.search-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}
</style>