<template>
  <section class="cta-five">
    <div class="container">
      <div class="cta-five__sliders">
        <!-- Slider da esquerda -->
        <div class="cta-five__slider">
          <div class="cta-five__wrapper" :style="wrapperStyleLeft">
            <div class="cta-five__item" v-for="(item, index) in itemsLeft" :key="`left-${index}`"
              @click="goToProduct(index, 'left')">
              <img :src="item.image" alt="Slide Image" />
            </div>
          </div>
        </div>

        <!-- Slider da direita -->
        <div class="cta-five__slider">
          <div class="cta-five__wrapper" :style="wrapperStyleRight">
            <div class="cta-five__item" v-for="(item, index) in itemsRight" :key="`right-${index}`"
              @click="goToProduct(index, 'right')">
              <img :src="item.image" alt="Slide Image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CtaFive",
  data() {
    return {
      itemsLeft: [
        { image: require('@/assets/images/banner3-01.png') },
        { image: require('@/assets/images/banner3-02.png') },
      ],
      itemsRight: [
        { image: require('@/assets/images/banner3-03.png') },
        { image: require('@/assets/images/banner3-04.png') },
      ],
      currentIndexLeft: 0,
      currentIndexRight: 0,
      timerLeft: null,
      timerRight: null,
    };
  },
  computed: {
    wrapperStyleLeft() {
      const translateX = -this.currentIndexLeft * 100;
      return {
        transform: `translateX(${translateX}%)`,
        transition: "transform 0.5s ease-in-out",
      };
    },
    wrapperStyleRight() {
      const translateX = -this.currentIndexRight * 100;
      return {
        transform: `translateX(${translateX}%)`,
        transition: "transform 0.5s ease-in-out",
      };
    },
  },
  methods: {
    nextSlideLeft() {
      this.currentIndexLeft = (this.currentIndexLeft + 1) % this.itemsLeft.length;
    },
    nextSlideRight() {
      this.currentIndexRight = (this.currentIndexRight + 1) % this.itemsRight.length;
    },
    startAutoSlide() {
      this.timerLeft = setInterval(() => {
        this.nextSlideLeft();
      }, 5000);

      this.timerRight = setInterval(() => {
        this.nextSlideRight();
      }, 5300);
    },
    goToProduct(index, slider) {
      let text = '';
      if (slider === 'left') {
        text = index === 0 ? 'turanza' : 'dueler';
      } else {
        text = index === 0 ? 'xbri' : 'brutus';
      }
      this.$router.push({ name: 'produtos', query: { text } });
    },
  },
  mounted() {
    this.startAutoSlide();
  },
  beforeDestroy() {
    clearInterval(this.timerLeft);
    clearInterval(this.timerRight);
  },
};
</script>

<style scoped>
.cta-five {
  display: flex;
  justify-content: center;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

}

.cta-five__sliders {
  display: flex;
  gap: 20px;
}

.cta-five__slider {
  position: relative;
  overflow: hidden;
  flex: 1;

}

.cta-five__wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
}

.cta-five__item {
  flex: 0 0 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.cta-five__item img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 15px;
  border: 1px solid red;
}

@media (max-width: 768px) {
  .cta-five__sliders {
    flex-direction: column;
    gap: 20px;
  }

  .cta-five__slider {
    width: 100%;
  }

  .cta-five__item {
    padding: 0;
  }
}

</style>