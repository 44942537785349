<template>
    <header class="main-header main-header--four sticky-header sticky-header--normal">
        <div class="container">
            <div class="header-top-line"></div>
            <div class="main-header__inner">
                <nav class="main-header__nav main-menu">
                    <ul class="main-menu__list">
                        <li class="dropdown">
                            <a href="#">
                                Categorias
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m4.5 5.25 7.5 7.5 7.5-7.5" />
                                </svg>
                            </a>
                            <ul>
                                <li v-for="department in departments" :key="department.id">
                                    <a @click.prevent="navigateToDepartment(department.slug)">
                                        {{ department.name }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li><a @click.prevent="navigateToCategory('nacionais')">Nacionais</a></li>
                        <li><a @click.prevent="navigateToCategory('importados')">Importados</a></li>
                        <li><a @click.prevent="navigateToCategory('para-motos')">Para Motos</a></li>
                        <li style="position: relative;">
                            <a href="https://www.google.com/maps?q=Imp%C3%A9rio+Pneus,+Camilo+Recalde,+Cd.+del+Este+7000&ftid=0x94f68554ddb7e827:0x47efc28fe581f75f&entry=gps&lucs=,94208286,94242568,94224825,94227247,94227248,47071704,47069508,94218641,94203019,47084304,94208458,94208447&g_ep=CAISDTYuMTM2LjAuODc4MzAYACDXggMqbCw5NDIwODI4Niw5NDI0MjU2OCw5NDIyNDgyNSw5NDIyNzI0Nyw5NDIyNzI0OCw0NzA3MTcwNCw0NzA2OTUwOCw5NDIxODY0MSw5NDIwMzAxOSw0NzA4NDMwNCw5NDIwODQ1OCw5NDIwODQ0N0ICUFk%3D&g_st=com.google.maps.preview.copy"
                                target="_blank" @mouseover="showLocationImage" @mouseleave="hideLocationImage">
                                Localização
                            </a>
                            <div v-if="isLocationImageVisible" class="location-image" @mouseover="showLocationImage"
                                @mouseleave="hideLocationImage">
                                <a href="https://www.google.com/maps?q=Imp%C3%A9rio+Pneus,+Camilo+Recalde,+Cd.+del+Este+7000&ftid=0x94f68554ddb7e827:0x47efc28fe581f75f&entry=gps&lucs=,94208286,94242568,94224825,94227247,94227248,47071704,47069508,94218641,94203019,47084304,94208458,94208447&g_ep=CAISDTYuMTM2LjAuODc4MzAYACDXggMqbCw5NDIwODI4Niw5NDI0MjU2OCw5NDIyNDgyNSw5NDIyNzI0Nyw5NDIyNzI0OCw0NzA3MTcwNCw0NzA2OTUwOCw5NDIxODY0MSw5NDIwMzAxOSw0NzA4NDMwNCw5NDIwODQ1OCw5NDIwODQ0N0ICUFk%3D&g_st=com.google.maps.preview.copy"
                                    target="_blank">
                                    <img src="@/assets/images/localizacao.png" alt="Localização" />
                                </a>
                            </div>

                        </li>

                    </ul>
                </nav>
                <div class="main-header__right">
                    <div class="mobile-nav__btn mobile-nav__toggler" @click="toggleMobileMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div v-if="isMobileMenuOpen" class="mobile-menu" ref="mobileMenu">
                <nav class="mobile-menu__nav">
                    <ul class="mobile-menu__list">
                        <li class="dropdown">
                            <a href="#" @click.prevent="toggleMobileDepartments">
                                Categorias
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="m4.5 5.25 7.5 7.5 7.5-7.5" />
                                </svg>
                            </a>
                            <ul v-if="isDepartmentsOpen">
                                <li v-for="department in departments" :key="department.id">
                                    <a @click.prevent="navigateToDepartment(department.slug)">
                                        {{ department.name }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li><a @click.prevent="navigateToCategory('nacionais')">Nacionais</a></li>
                        <li><a @click.prevent="navigateToCategory('importados')">Importados</a></li>
                        <li><a @click.prevent="navigateToCategory('para-motos')">Para Motos</a></li>
                        <a href="https://www.google.com/maps?q=Imp%C3%A9rio+Pneus,+Camilo+Recalde,+Cd.+del+Este+7000&entry=gps"
                            target="_blank" @touchstart="showLocationImage" @touchend="hideLocationImage"
                            @mouseover="showLocationImage" @mouseleave="hideLocationImage">
                            Localização
                        </a>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="header-bottom-line"></div>
    </header>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
    name: 'HeaderView',
    data() {
        return {
            departments: [],
            isMobileMenuOpen: false,
            isDepartmentsOpen: false,
            categorySlug: 'pneus',
            isLocationImageVisible: false,
            hideTimeout: null,
        };
    },
    created() {
        this.fetchDepartments();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods: {
        async fetchDepartments() {
            try {
                const response = await axios.get('https://api-imperiopneus.agcodecraft.com/api/public/categories');
                this.departments = response.data[0].departments;
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        },
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },
        toggleMobileDepartments() {
            this.isDepartmentsOpen = !this.isDepartmentsOpen;
        },
        navigateToDepartment(departmentSlug) {

            this.isMobileMenuOpen = false;
            this.$router.push({ name: 'produtos', params: { categoriaSlug: this.categorySlug, departamentoSlug: departmentSlug, query: { page: 1 } } });
        },
        navigateToCategory(categorySlug) {
            this.isMobileMenuOpen = false;

            const segmentId = ['nacionais', 'importados', 'para-motos'].includes(categorySlug)
                ? this.getSegmentIdFromCategory(categorySlug)
                : null;

            const query = segmentId ? { segment_id: segmentId } : {};

            this.$router.push({
                name: 'produtos',
                params: {
                    categoriaSlug: categorySlug
                },
                query: query 
            });
        },
        getSegmentIdFromCategory(categorySlug) {
            switch (categorySlug) {
                case 'nacionais':
                    return 2;
                case 'importados':
                    return 1;
                case 'para-motos':
                    return 3;
                default:
                    return null; 
            }
        },
        handleClickOutside(event) {
            const menu = this.$refs.mobileMenu;
            const toggleBtn = this.$refs.toggleButton;
            if (this.isMobileMenuOpen && menu && !menu.contains(event.target) && toggleBtn && !toggleBtn.contains(event.target)) {
                this.isMobileMenuOpen = false;
            }
        },
        showLocationImage() {
            if (this.hideTimeout) {
                clearTimeout(this.hideTimeout);
                this.hideTimeout = null;
            }
            this.isLocationImageVisible = true;
        },
        hideLocationImage() {
            this.hideTimeout = setTimeout(() => {
                this.isLocationImageVisible = false;
            }, 300);
        },
    },
};
</script>


<style scoped>
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
}

.main-header__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.main-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    max-width: 1000px;
    margin: 0 auto;
}

.main-menu__list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.header-top-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #d1d1d1;
}

.main-menu__list li {
    padding: 0 10px;
}

.main-header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    position: relative;
}

.header-bottom-line {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #d1d1d1;
}

.main-menu__list a,
.mobile-menu__list a {
    cursor: pointer;
}


.main-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.main-menu__list {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
}



.main-header__right {
    display: flex;
    align-items: center;
}

.mobile-nav__btn {
    margin-left: 20px;
}

.mobile-menu {
    display: block;
    background-color: white;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 1000;
}

.mobile-menu__nav {
    padding: 10px;
}

.mobile-menu__list {
    list-style: none;
    padding: 0;
}

.mobile-menu__list li {
    margin: 10px 0;
}

.mobile-menu__list a {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    color: #333;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.3s;
}

.mobile-menu__list a:hover {
    background-color: #f0f0f0;
    transform: scale(1.02);
}

.size-6 {
    width: 16px;
    height: 16px;
    color: currentColor;
    margin-left: 5px;
}

.mobile-nav__btn {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1100;
}

.location-image {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    background: white;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.location-image img {
    max-width: 400px;
    height: auto;
    cursor: pointer;
}
</style>