<template>
  <nav v-if="!isHomePage" aria-label="breadcrumb" class="breadcrumb-container">
    <ul class="breadcrumb">
      <li 
        v-for="(crumb, index) in breadcrumbs" 
        :key="index" 
        class="breadcrumb-item"
      >
        <router-link v-if="crumb.to" :to="crumb.to" class="breadcrumb-link">
          {{ crumb.breadcrumb.toUpperCase() }}
        </router-link>
        <span v-else class="breadcrumb-text">
          {{ crumb.breadcrumb.toUpperCase() }}
        </span>

       <!--  <span v-if="index < breadcrumbs.length - 1" class="breadcrumb-separator"> &gt; </span> -->
      </li>
    </ul>
  </nav>
</template>

<script>
import { useRoute } from 'vue-router';
import { ref, watch, onMounted } from 'vue';
import axios from 'axios';
import { previousRoute } from '@/router';

export default {
  name: 'Breadcrumbs',
  setup() {
    const route = useRoute();
    const breadcrumbs = ref([]);
    const categories = ref([]);
    const isHomePage = ref(false);

    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://api-imperiopneus.agcodecraft.com/api/public/categories');
        categories.value = response.data;
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };

    const fetchProductDetails = async (productSlug) => {
      if (!productSlug) return null;

      try {
        const response = await axios.get(
          `https://api-imperiopneus.agcodecraft.com/api/public/products/${productSlug}/details`
        );
        return response.data;
      } catch (error) {
        console.error('Erro ao buscar detalhes do produto:', error);
        return null;
      }
    };

    const updateBreadcrumbs = async () => {
      isHomePage.value = route.path === '/';

      const matchedBreadcrumbs = [];
      const categorySlug = route.params.categoriaSlug || previousRoute?.params?.categoriaSlug;
      const departmentSlug = route.params.departamentoSlug || previousRoute?.params?.departamentoSlug;
      const productSlug = route.params.slug;

      if (!categories.value.length) {
        breadcrumbs.value = [];
        return;
      }

      matchedBreadcrumbs.push({
        breadcrumb: 'Página Inicial',
        to: '/',
      });

      if (categorySlug) {
        const category = categories.value.find((cat) => cat.slug === categorySlug);
        if (category) {
          matchedBreadcrumbs.push({
            breadcrumb: category.name,
            to: `/produtos/${categorySlug}`, 
          });

          if (departmentSlug) {
            const department = category.departments.find((dep) => dep.slug === departmentSlug);
            if (department) {
              matchedBreadcrumbs.push({
                breadcrumb: department.name,
                to: `/produtos/${categorySlug}/${departmentSlug}`, 
              });
            }
          }
        }
      }

      if (productSlug) {
  const product = await fetchProductDetails(productSlug);

  let productTitle = 'Produto não encontrado';
  if (product) {
    productTitle = product.nome === product.titulo ? product.nome : `${product.nome} - ${product.titulo}`;
  }

  matchedBreadcrumbs.push({
    breadcrumb: productTitle,
    to: null, 
  });
}


      breadcrumbs.value = matchedBreadcrumbs;
    };

    watch(route, () => {
      updateBreadcrumbs();
    });

    onMounted(async () => {
      await fetchCategories();
      await updateBreadcrumbs();
    });

    return { breadcrumbs, isHomePage };
  },
};
</script>

<style scoped>
.breadcrumb-container {
  max-width: 1400px; 
  margin: 0 auto; 
  padding: 10px; 
}

.breadcrumb {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 14px;
  text-transform: uppercase; 
  flex-wrap: nowrap; 
}

.breadcrumb-item {
  display: inline-flex; 
  align-items: center;
}

.breadcrumb-link {
  color: #808080;
  text-decoration: none;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}

.breadcrumb-text {
  color: #555;
}

.breadcrumb-separator {
  margin: 0 8px;
  color: #555;
  font-weight: bold;
}
</style>
