<template>
    <section class="product-one">
        <div class="container">
            <h3 class="product-one__title title-red">Destaques</h3>
            <div class="container-carousel">

                <div id="productCarousel" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item" :class="{ active: index === 0 }"
                            v-for="(productChunk, index) in chunkedProducts" :key="index">
                            <div class="row justify-content-center">
                                <div class="row justify-content-center flex-row-wrap">
                                    <div class="product__item" v-for="item in productChunk" :key="item.id">
                                        <!-- Envolvendo todo o item de produto com o router-link -->
                                        <router-link :to="{ name: 'produto', params: { slug: item.slug } }" class="product__link">
                                            <div class="product__item__img">
                                                <img :src="item.imagens.length > 0 ? item.imagens[0].url : require('@/assets/images/produto-sem-imagem.png')"
                                                    :alt="item.nome" class="img-fluid" />
                                            </div>
                                            <div class="product__item__content">
                                                <h4 class="product__item__title">
                                                    <span v-if="item.titulo !== item.nome">
                                                        <span class="titulo">{{ item.titulo }}</span> <span
                                                            class="nome">{{ item.nome }}</span>
                                                    </span>
                                                    <span v-else>
                                                        <span class="titulo">{{ item.titulo }}</span>
                                                    </span>
                                                </h4>
                                                <div class="product__item__code">COD: {{ item.codigo }}</div>
                                                <div class="product__item__price">U$ {{ item.valor_venda }}</div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-controls">
                        <button class="carousel-control-prev custom-carousel-control" type="button"
                            data-bs-target="#productCarousel" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next custom-carousel-control" type="button"
                            data-bs-target="#productCarousel" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>


<script>
import axios from 'axios';
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
    name: 'ProductSection',
    setup() {
        const products = ref([]);
        const chunkedProducts = ref([]);
        const itemsPerPage = ref(5);

        const chunkArray = (array, chunkSize) => {
            const result = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                result.push(array.slice(i, i + chunkSize));
            }
            return result;
        };

        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://api-imperiopneus.agcodecraft.com/api/public/products?text=&category_id=13&department_id=5&page=1&order_by=tem_estoque,id&order=desc,desc');
                products.value = response.data.data;
                chunkedProducts.value = chunkArray(products.value, itemsPerPage.value);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        const updateChunkedProducts = () => {
            const width = window.innerWidth;
            if (width < 576) {
                itemsPerPage.value = 1;
            } else if (width < 768) {
                itemsPerPage.value = 2;
            } else if (width < 992) {
                itemsPerPage.value = 3;
            } else if (width < 1200) {
                itemsPerPage.value = 4;
            } else {
                itemsPerPage.value = 5;
            }
            chunkedProducts.value = chunkArray(products.value, itemsPerPage.value);
        };

        onMounted(() => {
            fetchProducts();
            updateChunkedProducts();
            window.addEventListener('resize', updateChunkedProducts);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', updateChunkedProducts);
        });

        return { products, chunkedProducts };
    },
};
</script>

<style scoped>
.titulo {
    font-weight: bold;
}

.nome {
    font-weight: normal;
}
body {
    font-family: 'Oswald', sans-serif;
}

.carousel-inner {
    max-width: 1200px;
    margin: 0 auto;
}

.title-red {
    color: rgba(226, 59, 51, 0.8);
    margin-bottom: 20px;
    font-size: 2.3rem;
    max-width: 1170px;
    margin: 0 auto;
    margin-bottom: 40px;
}



.container-carousel {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.carousel {
    max-width: 100%;
    margin: 0 auto;
}

.carousel-controls {
    position: absolute;
    top: 50%;
    width: calc(100% + 80px);
    left: -40px;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 10;
}

.product-one__title::after {
    position: absolute;
    right: 1px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: calc(100% - 180px);
    height: 2px;
    content: "";
    background-color: var(--karoons-border-color, #DEDEDE);
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

@media (max-width: 1200px) {
    .carousel-controls {
        width: 100%;
        left: 0;
    }

    .carousel-control-prev {
        left: 10px;
    }

    .carousel-control-next {
        right: 10px;
    }
}

@media (max-width: 768px) {

    .carousel-control-prev,
    .carousel-control-next {
        width: 30px;
        height: 30px;
    }

    .carousel-control-prev {
        left: 15px;
    }

    .carousel-control-next {
        right: 15px;
    }
}

@media (max-width: 576px) {

    .carousel-control-prev,
    .carousel-control-next {
        width: 25px;
        height: 25px;
    }

    .carousel-control-prev {
        left: 20px;
    }

    .carousel-control-next {
        right: 20px;
    }
}

.custom-carousel-control {
    background-color: #E23B33;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}

.custom-carousel-control:hover {
    background-color: #C12E29;
    opacity: 1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {

    width: 12px;
    height: 12px;
}



.row.flex-row-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}


@media (min-width: 1250px) {
    .product__item {
        width: calc(20% - 20px);
    }

    .row.flex-row-wrap {
        gap: 20px;
    }
}

@media (max-width: 1200px) {
    .product__item {
        width: calc(25% - 20px);
    }

    .carousel-control-prev {
        left: 10px;
    }

    .carousel-control-next {
        right: 10px;
    }
}

@media (max-width: 992px) {
    .product__item {
        width: calc(33.33% - 20px);
    }
}

@media (max-width: 768px) {
    .product__item {
        width: calc(50% - 20px);
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 30px;
        height: 30px;
    }

    .carousel-control-prev {
        left: 15px;
    }

    .carousel-control-next {
        right: 15px;
    }
}

@media (max-width: 576px) {
    .product__item {
        width: calc(100% - 20px);
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 25px;
        height: 25px;
    }

    .carousel-control-prev {
        left: 20px;
    }

    .carousel-control-next {
        right: 20px;
    }

    .product__item__img img {
        max-width: 50%;
        height: auto;
        object-fit: cover;
        border-radius: 8px;
    }

}
</style>
