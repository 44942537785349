<template>
  <div class="product-page">
    <LoadingSpinner :isLoading="isLoading" />

    <!-- Lista de Produtos -->

    <div class="product-list-section">
      <!-- Lista de Categorias -->
      <!--  <div class="categories-container" v-if="categories.length > 0">
        <ul class="category-list">
          <li v-for="category in categories" :key="category.id" class="category-item">
            <a @click.prevent="selectCategory(category)" class="category-link"
              :class="{ active: selectedCategory && selectedCategory.id === category.id }">
              {{ category.name }}
            </a>
          </li>
        </ul>
      </div> -->

      <div class="header">
        <h2 class="brand-title">{{ selectedTitle }}</h2>
        <div class="sort-options">
          <div class="custom-select-wrapper">
            <div class="custom-select">
              <select id="sort" v-model="sortOption">
                <option id="filtrar" value="">Filtrar</option>
                <option value="valor_venda:asc">Preço Menor</option>
                <option value="valor_venda:desc">Preço Maior</option>
                <option value="nome:asc">Nome: A-Z</option>
                <option value="nome:desc">Nome: Z-A</option>
              </select>
              <svg v-if="!sortOption" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor" class="icon">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M4.5 5.25l7.5 7.5 7.5-7.5m-15 6l7.5 7.5 7.5-7.5" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div v-if="filteredProducts.length === 0">
        <p>Nenhum produto disponível para esta marca.</p>
      </div>
      <div v-else class="product-list">
        <router-link v-for="(product, index) in filteredProducts" :key="index"
          :to="`/produto/${product.slug}?page=${currentPage.value}`" class="product__item" style="max-width: 250px;" @click="saveCurrentPage"> 
          <div class="product__item__img">
            <img :src="product.imagens[0]?.url || defaultImage" :alt="`Imagem do produto ${product.nome}`"
              class="img-fluid" />
          </div>
          <div class="product__item__content">
            <h4 class="product__item__title">
              <a :href="`/produto/${product.slug}?page=${currentPage.value}`">
                <span class="product__item__title--titulo">{{ product.titulo ? product.titulo : '' }}</span>
                <span class="product__item__title--nome">{{ product.nome ? product.nome : '' }}</span>
              </a>
            </h4>

            <div class="product__item__code">COD: {{ product.codigo }}</div>
            <div class="product__item__price" v-if="product.tem_estoque">{{ formatarPreco(product.valor_venda) }}</div>
            <small v-if="!product.tem_estoque" class="out-of-stock-tag">INDISPONÍVEL</small>
          </div>
        </router-link>

      </div>
      <div class="pagination-container" v-if="totalPages > 1">
        <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">
          Anterior
        </button>
        <div class="pagination-numbers">
          <button v-for="page in visiblePages" :key="page" @click="goToPage(page)"
            :class="{ active: page === currentPage }">
            {{ page }}
          </button>
          <span v-if="totalPages > visiblePages[visiblePages.length - 1]">...</span>
          <button v-if="currentPage < totalPages - 4" @click="goToPage(totalPages)">{{ totalPages }}</button>
        </div>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">
          Próxima
        </button>
      </div>



    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LoadingSpinner from '@/components/template/loading-spinner.vue';
import { fetchBrands, fetchProductsByBrandId, fetchCategoriesByBrandId } from '@/services/apiService';

const filteredProducts = ref([]);
const categories = ref([]);
const selectedTitle = ref('Produtos');
const sortOption = ref('');
const defaultImage = require('../assets/images/produto-sem-imagem.png');
const isLoading = ref(false);
const currentPage = ref(1);
const totalPages = ref(1);
const totalProducts = ref(0);
const route = useRoute();
const router = useRouter();
const brandId = ref(null);
const selectedCategory = ref(null);

const fetchCategories = async () => {
  try {
    if (!brandId.value) return;

    const response = await fetch(`https://api-imperiopneus.agcodecraft.com/api/public/categories?brand_id=${brandId.value}`);
    const data = await response.json();

    categories.value = data;
  } catch (error) {
    console.error('Erro ao buscar categorias:', error);
  }
};

const saveCurrentPage = () => {
  sessionStorage.setItem('currentPage', currentPage.value);
};

const fetchBrandBySlug = async (brandSlug) => {
  try {
    const brands = await fetchBrands();
    const brand = brands.find(b => b.slug === brandSlug);
    return brand ? { id: brand.id, name: brand.nome } : { id: null, name: '' };
  } catch (error) {
    console.error('Erro ao buscar marcas:', error);
    return { id: null, name: '' };
  }
};

const formatarPreco = (preco) => {
  const precoNumerico = parseFloat(preco);
  return isNaN(precoNumerico) ? 'Preço inválido' : `US$ ${precoNumerico.toFixed(2).replace('.', ',')}`;
};

const fetchProducts = async (page = 1) => {
  currentPage.value = page;
  isLoading.value = true;
  window.scrollTo(0, 0);

  if (!brandId.value) {
    isLoading.value = false;
    return;
  }

  try {
    // Se sortOption estiver vazio, aplica uma ordenação padrão
    const [orderBy, order] = sortOption.value.split(':');
    const params = {
      page: currentPage.value,
      order_by: orderBy || 'tem_estoque,id',  // Valor padrão
      order: order || 'desc',  // Valor padrão
      category_id: selectedCategory.value ? selectedCategory.value.id : '',
    };

    const response = await fetchProductsByBrandId(brandId.value, params);
    let products = response.data || [];

    // Separando os produtos com e sem estoque
    const inStock = products.filter(product => product.tem_estoque);
    const outOfStock = products.filter(product => !product.tem_estoque);

    // Ordenando os produtos com estoque de acordo com a opção de preço
    if (orderBy === 'valor_venda') {
      inStock.sort((a, b) => {
        const priceA = parseFloat(a.valor_venda);
        const priceB = parseFloat(b.valor_venda);
        return order === 'asc' ? priceA - priceB : priceB - priceA;
      });
    }

    // Combina produtos com e sem estoque, colocando os sem estoque por último
    filteredProducts.value = [...inStock, ...outOfStock];
    totalProducts.value = filteredProducts.value.length;
    totalPages.value = Math.ceil(totalProducts.value / 20);
  } catch (err) {
    console.error('Erro ao buscar produtos:', err);
  } finally {
    isLoading.value = false;
  }
};


// Watcher para mudanças no sortOption (ordem de exibição)
watch(sortOption, (newValue) => {
  fetchProducts(currentPage.value);
});

onMounted(async () => {
  currentPage.value = 1;

  const brand = await fetchBrandBySlug(route.params.brandSlug);
  brandId.value = brand.id;
  selectedTitle.value = brand.name || 'Produtos';

  await fetchCategories();

  if (route.params.categoriaSlug) {
    const category = categories.value.find(c => c.slug === route.params.categoriaSlug);
    if (category) {
      selectedCategory.value = category;
    }
  }

  await fetchProducts(currentPage.value);
});
</script>


<style scoped>
html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

}

.categories-container {
  margin-bottom: 20px;
}

.brand-title {
  font-family: 'Oswald', sans-serif;
}

.category-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.category-item {
  margin-right: 15px;

}

.category-link {
  padding: 10px 15px;
  background-color: #f0f0f0;
  border-radius: 8px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
  font-family: 'Oswald', sans-serif;
}

.category-link:hover {
  background-color: gray;
  color: white;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.product-page {
  display: flex;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  max-width: 1500px;
  margin: 0 auto;
  font-family: 'Oswald', sans-serif;
}

.category-sidebar {
  width: 280px;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  position: relative;
}

.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
}

.category-item {
  position: relative;
}

.category-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  color: #333;
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 12px;
  background: #ffffff;
  border: 1px solid transparent;
  transition: background 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
  font-weight: 600;
}

.category-link:hover,
.category-link.active {
  background-color: gray;
  color: #ffffff;
  border-color: gray;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.category-link i {
  transition: transform 0.3s ease;
}

.department-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 100%;
  top: 0;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  width: 200px;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.category-item:hover .department-list {
  display: block;
  opacity: 1;
}

.department-link {
  display: block;
  padding: 10px 16px;
  color: #333;
  text-decoration: none;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.department-link:hover {
  background: #f5f5f5;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.product-list-section {
  flex: 1;
  padding: 20px;
  max-width: 100vw;
  box-sizing: border-box;
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}





@media (max-width: 768px) {
  .product-list {
    flex-direction: column;
    align-items: center;
  }

  .product-item {
    width: 100%;
    max-width: 300px;
    margin-bottom: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .product-item {
    width: 45%;
    max-width: 260px;
  }
}

@media (min-width: 1201px) {
  .product-item {
    width: 22%;
    max-width: 260px;
  }
}

.linha-abaixo-titulo {
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
  color: gray;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #fff;
}

.pagination-button {
  background-color: #E23B33;
  /* Vermelho para os botões */
  color: #fff;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #ff3333;
}

.pagination-numbers {
  display: flex;
  gap: 5px;
  margin: 0 10px;
}

.pagination-numbers button {
  background-color: #fff;
  color: #E23B33;
  /* Vermelho para o texto */
  border: 2px solid #E23B33;
  padding: 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-numbers button.active {
  background-color: #E23B33;
  color: #fff;
}

.pagination-numbers button:hover:not(.active) {
  background-color: #E23B33;
  color: #fff;
}

.out-of-stock-tag {
  color: red;
  font-weight: bold;
  background-color: #f8d7da;
  padding: 3px 6px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 5px;
}

.pagination-numbers button.active {
  background: gray;
  color: #fff;
}

.pagination-numbers button:hover {
  background: gray;
  color: #fff;
}

@media (max-width: 768px) {
  .category-sidebar {
    display: none;
  }

  .product-page {
    padding: 1rem;
  }

  .pagination-controls {
    flex-direction: column;
  }

  .pagination-controls button {
    min-width: auto;

  }

  .pagination-numbers {
    display: none;
  }

  .pagination-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}


.sort-options select {
  padding: 4px 10px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid red;
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  appearance: none;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
  color: #827F7F
}

.custom-select-wrapper {
  position: relative;
  margin-right: 90px;
}

.custom-select select:focus {
  outline: none;
  border: 1px solid red;
  /* Mantém a borda vermelha */
}

.custom-select {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.custom-select select {
  text-align: left;
  line-height: 30px;
  padding: 0 10px;
}


.custom-select .icon {
  position: absolute;
  right: 15px;
  pointer-events: none;
  width: 16px;
  height: 16px;
}

.custom-select option#filtrar {
  text-align: left;
  padding-left: 10px;
}

.sort-options select:hover,
.sort-options select:focus {
  border-color: #5f5f5f;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 0 10px;
}

.pagination-controls button {
  padding: 10px 20px;
  margin: 0 8px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(145deg, gray, #C4A77D);
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background 0.3s, transform 0.2s;
  min-width: 100px;
  text-align: center;
}

.pagination-controls button:hover {
  background: linear-gradient(145deg, #C4A77D, gray);
  transform: scale(1.05);
}

.pagination-controls button:disabled {
  background: #E0D8C1;
  cursor: not-allowed;
  box-shadow: none;
}

.pagination-controls span {
  font-size: 16px;
  margin: 0 10px;
  color: #333;
  font-weight: 600;
}


@media (max-width: 768px) {
  .category-sidebar {
    display: none;
  }

  .product-page {
    padding: 1rem;
  }

  .pagination-controls {
    flex-direction: column;
  }

  .pagination-controls button {
    min-width: auto;

  }
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-left-color: gray;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 900px) {
  .sort-select {
    margin-right: 0;
    /* Remove a margem em telas menores que 768px */
  }
}
</style>
