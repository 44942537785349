<template>
    <footer class="main-footer-four" style="background-color: #000000;">
        <div class="main-footer-four__top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-4">
                        <div class="footer-widget footer-widget--about">
                            <a href="/" class="footer-widget__logo">
                                <img src="@/assets/images/logowhite.png" width="300" alt="Karoons HTML Template">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-2">
                    </div>
                </div>
            </div>
        </div>
        <div class="main-footer-four__bottom" style="background-color: #000000;">
            <div class="container">
                <div class="main-footer-four__bottom__inner">
                    <p class="main-footer-four__copyright">
                        &copy; Copyright <span class="dynamic-year"></span> Imperio Pneus.
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>


  <script>
  // @ is an alias to /src
  
  export default {
    name: 'FooterView',
  
  }
  </script>
  