<template>
  <div class="slider-wrapper">
    <div class="slider-container">
      <!-- Contêiner das imagens que se movem -->
      <div
        class="slider-images"
        :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
      >
        <div class="slider-image" v-for="(image, index) in images" :key="index">
          <img :src="image" alt="Slider Image" />
        </div>
      </div>

      <!-- Botões de navegação dentro da imagem -->
      <div class="nav-buttons">
        <button
          v-for="(image, index) in images"
          :key="index"
          :class="['nav-button', { active: currentIndex === index }]"
          @click="goToImage(index)"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('@/assets/images/banner1-01.png'),
        require('@/assets/images/banner1-02.png'),
        require('@/assets/images/banner1-03.png'),
      ],
      currentIndex: 0,
      interval: null,
    };
  },
  mounted() {
    this.startImageChange();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    startImageChange() {
      this.interval = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
      }, 7000);
    },
    goToImage(index) {
      this.currentIndex = index;
    },
    stopInterval() {
      clearInterval(this.interval);
    },
  },
};
</script>

<style scoped>
.slider-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.slider-container {
  position: relative;
  width: 100%;
  max-width: 1900px;
  height: auto;
  overflow: hidden;
}

.slider-images {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-image {
  width: 100%;
  height: auto;
  max-width: 1900px;
  flex-shrink: 0;
}

.slider-image img {
  width: 100%;
  height: auto;
}

.nav-buttons {
  position: absolute;
  top: 50%;
  left: 10px; 
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
}

.nav-button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  border: 2px solid white;
  transition: background-color 0.3s;
  cursor: pointer;
}

.nav-button.active {
  background-color: darkred;
}

.nav-button:hover {
  background-color: darkred;
}

.image-indicator {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 1;
  width: 100%;
  pointer-events: none;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: rgba(255, 255, 255, 1);
}

.indicator:hover {
  background-color: rgba(255, 255, 255, 1);
}

@media (max-width: 1900px) {
  .slider-container {
    max-width: 100%;
  }
}

@media (max-width: 700px) {
  .nav-buttons {
    left: 10px;
  }
}
@media (max-width: 700px) {
  .nav-buttons {
    display: none;
  }
}

</style>
