import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import ProdutoView from '@/views/ProdutoView.vue'
import ProdutosView from '@/views/ProdutosView.vue'
import MarcaProdutosView from '@/views/MarcaProdutosView.vue'
import VendedoresView from '@/views/VendedoresView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { breadcrumb: 'Home' }
  },
  {
    path: '/produto/:slug?',
    name: 'produto',
    component: ProdutoView,
    meta: { breadcrumb: 'Produto' },
    props: route => ({
      slug: route.params.slug
    })
  },
  {
    path: '/produtos/:categoriaSlug?/:departamentoSlug?',
    name: 'produtos',
    component: ProdutosView,
    meta: { breadcrumb: 'Produtos' },
    props: route => ({
      departamentoSlug: route.params.departamentoSlug,
      categoriaSlug: route.params.categoriaSlug,
      text: route.query.text, 
      brandSlug: route.query.brandSlug  
    }),
  },
  {
    path: '/vendedores',
    name: 'vendedores',
    component: VendedoresView,
    meta: { breadcrumb: 'Vendedores' },
    props: true
  },
  {
    path: '/marca/:brandSlug?/:categoriaSlug?',
    name: 'marca',
    component: MarcaProdutosView,
    meta: { breadcrumb: 'Marca' },
    props: route => ({
      brandSlug: route.params.brandSlug,
      categoriaSlug: route.params.categoriaSlug,
    }),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

let previousRoute = null;

router.beforeEach((to, from, next) => {
  if (from.name === 'produtos' && to.name === 'produto') {
    previousRoute = from;
  }
  
  next();
});

export { previousRoute };
export default router;
