<template>
  <section class="product-two">
    <div class="container-carousel">
      <div class="product-two__top">
        <div class="container">
          <h3 class="product-one__title title-red">MARCAS</h3>
          <div class="underline"></div>
        </div>
      </div>

      <div id="brandCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div
            class="carousel-item"
            :class="{ active: index === 0 }"
            v-for="(brandChunk, index) in chunkedBrands"
            :key="index"
          >
            <div class="brands-wrapper">
              <router-link
                v-for="(brand, idx) in brandChunk"
                :key="brand.id"
                :to="{ name: 'marca', params: { brandSlug: brand.slug, query: { page: 1 } } }"
                class="brand-item"
              >
                <div class="product__item">
                  <div class="product__item__img">
                    <img v-if="brand.logo" :src="brand.logo" :alt="brand.nome" />
                    <img v-else :src="require('@/assets/images/marca-sem-imagem.png')" alt="Imagem não disponível" class="default-img" />
                    <div v-if="!brand.logo" class="brand-name">{{ brand.nome }}</div>
                  </div>
                  <div class="product-arrow">➔</div>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div class="carousel-controls">
          <button class="carousel-control-prev custom-carousel-control" type="button" data-bs-target="#brandCarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next custom-carousel-control" type="button" data-bs-target="#brandCarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import axios from 'axios';
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'BrandSection',
  setup() {
    const brands = ref([]);
    const chunkedBrands = ref([]);
    const itemsPerPage = ref(5); 

    const chunkArray = (array, chunkSize) => {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    };

    const fetchBrands = async () => {
      try {
        const response = await axios.get('https://api-imperiopneus.agcodecraft.com/api/public/brands');
        brands.value = response.data;
        chunkedBrands.value = chunkArray(brands.value, itemsPerPage.value);
      } catch (error) {
        console.error('Erro ao buscar marcas:', error);
      }
    };

    const updateChunkedBrands = () => {
      const width = window.innerWidth;
      if (width < 768) {
        itemsPerPage.value = 1;
      } else if (width < 1000) {
        itemsPerPage.value = 2;
      } 
      else if (width < 1200) {
        itemsPerPage.value = 3;
      } else {
        itemsPerPage.value = 5;
      }
      chunkedBrands.value = chunkArray(brands.value, itemsPerPage.value);
    };

    onMounted(() => {
      fetchBrands();
      updateChunkedBrands();
      window.addEventListener('resize', updateChunkedBrands);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateChunkedBrands);
    });

    return { brands, chunkedBrands };
  },
};
</script>
<style scoped>
.product-two {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
  margin: 0 auto;
  max-width: 1200px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  border-radius: 10px;
}

.brands-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
}
.product-one__title::after {
  position: absolute;
  right: 1px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: calc(100% - 140px);
  height: 2px;
  content: "";
  background-color: var(--karoons-border-color, #DEDEDE);
}
.product__item {
  text-align: center;
  border-radius: 10px;
  background-image: url('@/assets/images/fundo-das-marcas.png');
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  background-color: #F7F7F7;
  padding-top: 10px;
}

.brands-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.brand-item {
  flex: 0 0 auto;
  min-width: 200px;
  margin: 0 10px;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #E23B33;

  width: calc(20% - 20px);
  overflow: hidden;
  height: 270px;
}

.product-arrow {
  color: #E23B33;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  background-color: white;
}

.title-red {
    color: rgba(226, 59, 51, 0.8); 
    margin-bottom: 20px;
    font-size: 2.3rem;
    max-width: 1170px;  
    margin: 0 auto;  
    margin-bottom: 40px;
}

.custom-carousel-control {
  background-color: #E23B33;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.custom-carousel-control:hover {
  background-color: #C12E29;
  opacity: 1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 12px;
  height: 12px;
}

.carousel-controls {
  position: absolute;
  top: 50%;
  width: calc(100% + 80px);
  left: -40px;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

@media (max-width: 1200px) {
  .carousel-controls {
    width: 100%;
    left: 0;
  }

  .carousel-control-prev {
    left: 10px;
  }

  .carousel-control-next {
    right: 10px;
  }
}

@media (max-width: 768px) {
  .carousel-control-prev,
  .carousel-control-next {
    width: 30px;
    height: 30px;
  }
  .carousel-control-prev {
    left: 15px;
  }

  .carousel-control-next {
    right: 15px;
  }
}

@media (max-width: 576px) {
  .carousel-control-prev,
  .carousel-control-next {
    width: 25px;
    height: 25px;
  }
  .carousel-control-prev {
    left: 20px;
  }

  .carousel-control-next {
    right: 20px;
  }
}

/* Imagem de fallback */
.default-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top: 20px;
}

.brand-name {
  font-size: 1rem;
  color: #333; /* Ajuste conforme necessário */
  transition: transform 500ms ease, color 500ms ease; /* Suaviza as mudanças */
  transform: scale(1); /* Garante que o elemento comece centralizado */
  display: inline-block; /* Impede que o texto se comporte de forma imprevisível */
  position: absolute;
  top: 10px;  /* Coloca o nome no topo */
  left: 50%;
  transform: translateX(-50%) scale(1); /* Centraliza horizontalmente e aplica escala */
  font-size: 1.5rem;
  color: #393939;
  font-weight: 900;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  z-index: 2;
}

/* Efeito de ampliação ao passar o mouse */
.product__item:hover .brand-name {
  transform: translateX(-50%) scale(1.1); /* Ampliação sem mover */
  color: #000; /* Alteração opcional de cor */
}

.product__item:hover .brand-name {
  transform-origin: center center; /* Garantir que a escala ocorra a partir do centro */
}
</style>
