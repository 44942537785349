<template>
  <section class="image-display">
    <img :src="imageSrc" alt="Displayed Image" class="responsive-image" />
  </section>
</template>

<script>
export default {
  name: 'ImageDisplay',
  data() {
    return {
      imageSrc: require('@/assets/images/banner2-01.png')  // Caminho da sua imagem
    };
  },
};
</script>

<style scoped>
.image-display {
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.responsive-image {
  max-width: 1200px; 
  width: 100%; 
  height: auto; 
  display: block;
  border-radius: 10px;
}
</style>
