<template>
    <div class="product-one">
        <div class="container">
            <h3 class="product-one__title title-red">BUSCAR PNEUS</h3>
            <section class="tire-search" :style="{ backgroundImage: `url(${backgroundImage})` }">
                <div class="container">
                    <div class="search-container">
                        <!-- Busca por Medida -->
                        <div class="search-by-size">
                            <h4 class="buscar">Buscar por Medida</h4>
                            <form @submit.prevent="searchBySize" class="d-flex flex-wrap align-items-center">
                                <!-- Largura -->
                                <div class="input-container">
                                    <label for="width" class="input-label">LARGURA</label>
                                    <div class="dropdown-container">
                                        <select v-model="width" class="form-control rounded-input">
                                            <option value="" disabled selected>Selecione</option>
                                            <option value="145">145</option>
                                            <option value="155">155</option>
                                            <option value="165">165</option>
                                            <option value="175">175</option>
                                            <option value="185">185</option>
                                            <option value="195">195</option>
                                            <option value="205">205</option>
                                            <option value="215">215</option>
                                            <option value="225">225</option>
                                            <option value="235">235</option>
                                            <option value="245">245</option>
                                            <option value="255">255</option>
                                            <option value="265">265</option>
                                            <option value="275">275</option>
                                            <option value="285">285</option>
                                            <option value="295">295</option>
                                            <option value="305">305</option>
                                            <option value="315">315</option>
                                            <option value="325">325</option>
                                            <option value="335">335</option>
                                            <option value="345">345</option>
                                            <option value="355">355</option>
                                            <option value="365">365</option>
                                        </select>
                                        <span class="dropdown-arrow">
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </div>
                                </div>

                                <!-- Altura -->
                                <div class="input-container">
                                    <label for="height" class="input-label">ALTURA</label>
                                    <div class="dropdown-container">
                                        <select v-model="height" class="form-control rounded-input">
                                            <option value="" disabled selected>Selecione</option>
                                            <option value="30">30</option>
                                            <option value="35">35</option>
                                            <option value="40">40</option>
                                            <option value="45">45</option>
                                            <option value="50">50</option>
                                            <option value="55">55</option>
                                            <option value="60">60</option>
                                            <option value="65">65</option>
                                            <option value="70">70</option>
                                            <option value="75">75</option>
                                            <option value="80">80</option>
                                            <option value="85">85</option>
                                            <option value="90">90</option>
                                            <option value="95">95</option>
                                        </select>
                                        <span class="dropdown-arrow">
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </div>
                                </div>

                                <!-- Aro -->
                                <div class="input-container">
                                    <label for="rim" class="input-label">ARO</label>
                                    <div class="dropdown-container">
                                        <select v-model="rim" class="form-control rounded-input">
                                            <option value="" disabled selected>Selecione</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                        </select>
                                        <span class="dropdown-arrow">
                                            <i class="fa-solid fa-chevron-down"></i>
                                        </span>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary">PESQUISAR</button>
                            </form>
                        </div>

                        <!-- Busca por Veículo -->
                        <div class="search-by-vehicle">
                            <div>
                                <h4 class="buscar">Buscar por Veículo</h4>
                                <p class="vehicle-info">*Válido para veículos com rodas originais de fábrica*</p>
                            </div>

                            <form @submit.prevent="searchByVehicle" class="d-flex flex-wrap align-items-center">
                                <!-- Marca -->
                                <div class="input-container">
                                    <label for="brand" class="input-label">MARCA</label>
                                    <div class="dropdown-container">
                                        <select v-model="selectedBrand" @change="fetchModels"
                                            class="form-control rounded-input vibrant-select">
                                            <option value="" disabled selected>Selecione</option>
                                            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                                                {{ brand.nome }}
                                            </option>
                                        </select>
                                        <span v-if="!selectedBrand" class="dropdown-arrow">
                                            <i class="fa-solid fa-chevron-down text-danger"></i>
                                        </span>
                                    </div>
                                </div>

                                <!-- Modelo -->
                                <div class="input-container">
                                    <label for="model" class="input-label">MODELO</label>
                                    <div class="dropdown-container">
                                        <select v-model="selectedModel" @change="fetchYears"
                                            class="form-control rounded-input vibrant-select"
                                            :class="{ 'active-select': selectedBrand }" :disabled="!selectedBrand">
                                            <option value="" disabled selected>Selecione</option>
                                            <option v-for="model in models" :key="model.id" :value="model.id">
                                                {{ model.nome }}
                                            </option>
                                        </select>
                                        <span v-if="!selectedModel" class="dropdown-arrow">
                                            <i class="fa-solid fa-chevron-down text-danger"></i>
                                        </span>
                                    </div>
                                </div>

                                <!-- Ano -->
                                <div class="input-container">
                                    <label for="year" class="input-label">ANO</label>
                                    <div class="dropdown-container">
                                        <select v-model="selectedYear" class="form-control rounded-input vibrant-select"
                                            :disabled="!selectedModel">
                                            <option value="" disabled selected>Selecione</option>
                                            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                        </select>
                                        <span v-if="!selectedYear" class="dropdown-arrow">
                                            <i class="fa-solid fa-chevron-down text-danger"></i>
                                        </span>
                                    </div>
                                </div>

                                <button type="submit" class="btn btn-primary"
                                    :disabled="!selectedYear">PESQUISAR</button>
                            </form>
                        </div>

                        <!-- Logo como marca d'água -->
                        <img src="@/assets/images/logo.png" alt="Logo" class="watermark" />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
    name: 'TireSearch',
    setup() {
        const width = ref('');
        const height = ref('');
        const rim = ref('');
        const widthOptions = ref(['195', '205', '215', '225', '235', '245']);
        const heightOptions = ref(['40', '45', '50', '55', '60']);
        const rimOptions = ref(['13', '14', '15', '16', '17', '18', '19', '20', '21', '22']);
        const selectedBrand = ref('');
        const selectedModel = ref('');
        const selectedYear = ref('');
        const brands = ref([]);
        const models = ref([]);
        const years = ref([]);
        const router = useRouter();
        const searchQuery = ref('');



        const showWidthOptions = ref(false);
        const showHeightOptions = ref(false);
        const showRimOptions = ref(false);

        const selectWidth = (option) => {
            width.value = option;
            showWidthOptions.value = false;
        };

        const selectHeight = (option) => {
            height.value = option;
            showHeightOptions.value = false;
        };

        const selectRim = (option) => {
            rim.value = option;
            showRimOptions.value = false;
        };

        const hideWidthOptions = () => {
            setTimeout(() => {
                showWidthOptions.value = false;
            }, 100);
        };

        const hideHeightOptions = () => {
            setTimeout(() => {
                showHeightOptions.value = false;
            }, 100);
        };

        const hideRimOptions = () => {
            setTimeout(() => {
                showRimOptions.value = false;
            }, 100);
        };

        const searchBySize = () => {
            if (width.value && height.value && rim.value) {
                const sizeQuery = `${width.value}/${height.value}R${rim.value}`;
                console.log(`Buscando pneus com medida: ${sizeQuery}`);

                router.push({
                    name: 'produtos',
                    query: {
                        text: sizeQuery
                    }
                });
            }
        };

        const searchByVehicle = () => {
            if (selectedBrand.value && selectedModel.value && selectedYear.value) {
                // Aqui você usa a variável searchQuery com o valor de relacao_nome
                console.log(`Buscando pneus para o veículo: ${searchQuery.value}`);

                router.push({
                    name: 'produtos',
                    query: {
                        text: searchQuery.value
                    }
                });
            }
        };


        const fetchBrands = async () => {
            const response = await axios.get('https://api-imperiopneus.agcodecraft.com/api/public/vehicle-brands');
            brands.value = response.data.sort((a, b) => a.nome.localeCompare(b.nome));
        };


        const fetchModels = async () => {
            if (!selectedBrand.value) return;

            const response = await axios.get(`https://api-imperiopneus.agcodecraft.com/api/public/vehicles?veiculo_marca_id=${selectedBrand.value}`);
            models.value = response.data.data.sort((a, b) => a.nome.localeCompare(b.nome)); // Ordena os modelos por nome
            selectedModel.value = '';
            years.value = [];
            selectedYear.value = '';
        };

        const fetchYears = async () => {
            if (!selectedBrand.value || !selectedModel.value) return;

            const response = await axios.get(`https://api-imperiopneus.agcodecraft.com/api/public/vehicles?veiculo_marca_id=${selectedBrand.value}&veiculo_modelo_id=${selectedModel.value}`);
            const vehicleData = response.data.data[0];

            years.value = vehicleData.veiculo_anos.map(year => year.ano).sort((a, b) => a - b); // Ordena os anos
            const relacaoNome = vehicleData.veiculo_anos[0]?.relacao_nome || '';
            if (relacaoNome) {
                searchQuery.value = relacaoNome.replace(/\s+/g, '');
            }

            selectedYear.value = '';
        };



        fetchBrands();

        return {

            width,
            height,
            rim,
            selectedBrand,
            selectedModel,
            selectedYear,
            brands,
            models,
            years,
            searchBySize,
            searchByVehicle,
            fetchModels,
            fetchYears,
            backgroundImage: require('@/assets/images/backgroundBusca.png'),
        };
    },
};
</script>


<style scoped>
.product-one {
    margin: 0 auto;
    max-width: 1200px;
    margin-top: 40px;
}

.buscar {
    font-size: 2.5rem;
    color: #656565;
    margin-bottom: 20px;
    margin-top: 50px;

}

.title-red {
    color: rgba(226, 59, 51, 0.8);
    margin-bottom: 20px;
    font-size: 2.3rem;
    max-width: 1170px;
    margin: 0 auto;
    margin-bottom: 40px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;

}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
    text-align: center;
}

.vehicle-info {
    margin-top: -25px;
    font-size: 0.9rem;
    color: #666;
    text-align: center;
}

input[type="number"] {
    width: 150px;
    padding: 10px;
    font-size: 1rem;
    border-radius: 50px;
    border: 1px solid #E23B33;
    transition: border-color 0.3s, box-shadow 0.3s;
    text-align: center;
}

input[type="number"]:focus {
    border-color: #FF9800;
    box-shadow: 0 0 6px rgba(255, 152, 0, 0.5);
    outline: none;
    text-align: center;

}

.tire-search {
    position: relative;
    padding: 20px 20px;
    border-radius: 15px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1), 0 0 0 0.5px #E23B33;
    margin: 40px auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: none;


    .search-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: stretch;
        justify-content: center;
        height: 100%;
        padding-left: 60px;
        padding-right: 60px;
        margin-bottom: 40px;
    }
}


.search-by-size,
.search-by-vehicle {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    padding: 0 10px;

}

form {
    display: flex;
    align-items: center;
    gap: 20px;
}

.rounded-input {
    border: 1px solid #E23B33;
    border-radius: 50px;
    padding: 5px 7px;
    width: 150px;
    transition: border-color 0.3s, box-shadow 0.3s;
    font-size: 1rem;
    text-align: center;
    height: 35px;
}

.rounded-input:focus {
    border-color: #FF9800;
    box-shadow: 0 0 6px rgba(255, 152, 0, 0.5);
    outline: none;
}

.btn {
    background-color: #E23B33;
    color: white;
    border: none;
    padding: 8px 30px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 30px;
}

.btn:disabled {
    background-color: #B0BEC5;
    cursor: not-allowed;
    transform: none;
}

.btn:hover {
    background-color: #D32F2F;
}

.watermark {
    position: absolute;
    bottom: 10px;
    right: 20px;
    opacity: 0.5;
    width: 150px;
    filter: grayscale(100%) contrast(100%);
}



input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;

    appearance: textfield;

}


input[type="number"] {
    width: 150px;
    padding: 10px;
    font-size: 1rem;
    border-radius: 50px;
    border: 1px solid #E23B33;
    transition: border-color 0.3s, box-shadow 0.3s;
}

input[type="number"]:focus {
    border-color: #FF9800;
    box-shadow: 0 0 6px rgba(255, 152, 0, 0.5);
    outline: none;
}


.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -10px;
    gap: 5px;

}


.input-label {
    font-size: 1rem;
    color: #696969;
    text-align: center;
    margin-bottom: -7px;
    width: 100%;

}

input[type="number"]:focus {
    outline: none;
}

.dropdown-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 150px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #E23B33;
    background-color: white;
    z-index: 9999;
    /* Ajuste o z-index conforme necessário */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.dropdown-menu div {
    padding: 8px;
    cursor: pointer;
    text-align: center;
}

.dropdown-menu div:hover {
    background-color: #f1f1f1;
}

.dropdown-arrow {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    pointer-events: none;
    color: red;
}
</style>
